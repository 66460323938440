import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScaService {
  constructor(private http: HttpClient) {}

  sendEmail({ email, reservation_id, payment_id, lang }): Observable<any> {
    if (payment_id) {
      return this.http.post(
        `reservation/${reservation_id}/payment/${payment_id}/sca?lang=${lang}`,
        { email },
      );
    }
    return this.http.post(`reservation/${reservation_id}/sca?lang=${lang}`, {
      email,
    });
  }

  getWhatsappLink(id: number) {
    return this.http.get(`sca/${id}/whatsapp_message`);
  }
}
